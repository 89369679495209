import { RequestPollOption } from "./getRequestPoll"
import { isPlatformNative } from "@helpers/isPlatformNative"

export enum EmbeddedMessageEvent {
    VIEW_OPTION_DETAILS = "viewOptionDetails",
    ANALYTICS_EVENT = "analyticsEvent",
    OPEN_LINK = "openLink",
}

export type EmbeddedMessage =
    | {
          event: EmbeddedMessageEvent.VIEW_OPTION_DETAILS
          payload: {
              option: RequestPollOption
          }
      }
    | {
          event: EmbeddedMessageEvent.ANALYTICS_EVENT
          payload: {
              event: string
              parameters?: Record<string, string | number | boolean | undefined>
          }
      }
    | {
          event: EmbeddedMessageEvent.OPEN_LINK
          payload: {
              url: string
              newTab: boolean
          }
      }

export const sendWrapperMessage = (message: EmbeddedMessage) => {
    if (isPlatformNative()) return
    const payload = JSON.stringify(message)
    // @ts-ignore
    if (window.ReactNativeWebView) {
        // ***************************************************************
        // React Native (iOS & Android)
        // ***************************************************************

        // @ts-ignore
        window.ReactNativeWebView.postMessage(payload)
    } else if (
        // @ts-ignore
        window.webkit &&
        // @ts-ignore
        window.webkit.messageHandlers &&
        // @ts-ignore
        window.webkit.messageHandlers.PollsMessageHandler
    ) {
        // ***************************************************************
        // Native iOS
        // ***************************************************************

        // @ts-ignore
        window.webkit.messageHandlers.PollsMessageHandler.postMessage(payload)
    } else if (
        // @ts-ignore
        window.PollsAndroidInterface &&
        // @ts-ignore
        window.PollsAndroidInterface.handleMessage
    ) {
        // ***************************************************************
        // Native Android
        // ***************************************************************

        // @ts-ignore
        window.PollsAndroidInterface.handleMessage(payload)
    } else {
        // ***************************************************************
        // iFrame (Web)
        // ***************************************************************

        window.parent?.postMessage(payload, "*")
    }
}
