import { Image, Linking, Pressable, View } from "react-native"
import useStyles from "@helpers/hooks/useStyles"
import React from "react"
import { CustomerInfoModalStyles } from "./CustomerInfoModal.styles"
import { Title } from "@components"
import { InfoLink } from "./InfoLink"
import { getAppConfig } from "@services/appConfig"
import { useTranslation } from "react-i18next"
import links from "@config/links"

const CustomerInfoModal = () => {
    const { t } = useTranslation()
    const { styles, theme } = useStyles(CustomerInfoModalStyles)
    const { tenantConfig } = getAppConfig()

    const downloadPolls = () => {
        Linking.openURL(links.getPolls)
    }

    return (
        <View style={styles.container}>
            <Title title={t("resources")} style={styles.header} />
            <View style={styles.divider} />
            {tenantConfig.infoModalLinks.map((item, index) => (
                <InfoLink
                    key={index}
                    title={item.title}
                    subtitle={item.subtitle}
                    url={item.url}
                />
            ))}
            {/* <Pressable onPress={downloadPolls}>
                <Image
                    source={{
                        uri: theme.image.pollsAdvertise,
                    }}
                    style={styles.image}
                />
            </Pressable> */}
        </View>
    )
}

export default CustomerInfoModal
