import { EmbeddedState } from "@contexts/AppProvider/AppReducer"
import {
    PollSettings,
    PostVoteAction,
    PostVoteActionMap,
    PostVoteActionSelector,
    PostVoteActionType,
} from "@types"
import { isPlatformMobile } from "@helpers/isPlatformMobile"
import { isPlatformNative } from "@helpers/isPlatformNative"
import { getAppConfig } from "@services/appConfig"

export const getPostVoteAction = (options: {
    pollSettings: PollSettings
    embeddedState: EmbeddedState | undefined
}): PostVoteAction => {
    const { pollSettings, embeddedState } = options
    const postVoteActionMap: PostVoteActionMap =
        pollSettings.postVoteActionMap ?? {
            default: {
                type:
                    pollSettings.postVoteAction ??
                    PostVoteActionType.Values.none,
            },
        }

    let selector: PostVoteActionSelector

    if (embeddedState) {
        switch (embeddedState) {
            case EmbeddedState.SDK_MOBILE_APP_IOS: {
                selector = PostVoteActionSelector.Values.mobileApp
                break
            }
            case EmbeddedState.SDK_MOBILE_APP_ANDROID: {
                selector = PostVoteActionSelector.Values.mobileApp
                break
            }
            case EmbeddedState.SDK_WEB_MOBILE: {
                selector = PostVoteActionSelector.Values.mobileWeb
                break
            }
            case EmbeddedState.SDK_WEB_DESKTOP: {
                selector = PostVoteActionSelector.Values.desktopWeb
                break
            }
            default: {
                selector = PostVoteActionSelector.Values.mobileApp
                break
            }
        }
    } else {
        if (isPlatformNative()) {
            selector = PostVoteActionSelector.Values.mobileApp
        } else if (isPlatformMobile()) {
            selector = PostVoteActionSelector.Values.mobileWeb
        } else {
            selector = PostVoteActionSelector.Values.desktopWeb
        }
    }

    let postVoteAction: PostVoteAction = postVoteActionMap.default

    switch (selector) {
        case PostVoteActionSelector.Values.desktopWeb: {
            postVoteAction =
                postVoteActionMap.desktopWeb ?? postVoteActionMap.default
            break
        }
        case PostVoteActionSelector.Values.mobileWeb: {
            postVoteAction =
                postVoteActionMap.mobileWeb ?? postVoteActionMap.default
            break
        }
        case PostVoteActionSelector.Values.mobileApp: {
            postVoteAction =
                postVoteActionMap.mobileApp ?? postVoteActionMap.default
            break
        }
    }

    return postVoteAction
}

export const getPostVoteActionImagePayload = (options: {
    postVoteAction: PostVoteAction
    tenantOverrideKey?: string
}): {
    imageUrl?: string
    url?: string
} => {
    const { tenantOverrideKey, postVoteAction } = options

    const tenantName =
        tenantOverrideKey ?? getAppConfig().tenantConfig.backendTenantName

    let imageId: string | undefined = undefined
    let url: string | undefined = undefined
    if (postVoteAction.type === PostVoteActionType.Values.image) {
        imageId = postVoteAction.imageId
        url = postVoteAction.url
    }

    let imageUrl: string | undefined = undefined
    if (tenantName && imageId) {
        imageUrl = `https://polls-platform.s3.amazonaws.com/customer-assets/${tenantName}/imageIds/${imageId}.png`
    }

    return {
        imageUrl,
        url,
    }
}
